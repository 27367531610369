window.addEventListener('load', () => {
    
    document.querySelector('.burger').addEventListener('click', function () {
        document.querySelector('body').classList.toggle('opened');
        document.querySelector('.burger').classList.toggle('open');
        document.querySelector('.menu-mobile').classList.toggle('open');
    });
    let menuOpened = false;
    
    document.querySelectorAll('.menu-mobile > li').forEach((itm) => {
        itm.addEventListener('click', () => {
            const wasActive = itm.classList.contains('active');
            document.querySelectorAll('.menu-mobile > li').
            forEach((itm) => { itm.classList.remove('active'); });
            if (!wasActive) {
                itm.classList.add('active');
            }
        });
    });
    document.querySelectorAll('.menu-www > li').forEach((itm) => {
        itm.addEventListener('mouseover', () => {
            document.querySelectorAll('.menu-www > li, .submenu-www > li').
            forEach((itm) => { itm.classList.remove('active'); });
            
            menuOpened = true;
            itm.classList.add('active');
            const sub = itm.dataset.target;
            if (sub) {
                document.querySelector('#' + sub).classList.add('active');
                document.querySelector('header').addEventListener('mouseleave', () => {
                    document.querySelector('#' + sub).classList.remove('active');
                }, { once: true });
            }
        });
    });
    
    // Close menu on click outside + click on menu item
    document.querySelector('body').addEventListener('click', (evt) => {
        if (menuOpened) {
            if (evt.target.closest('header') === null ||
                (evt.target.tagName === 'A' &&
                    !evt.target.hasAttribute('data-submenu'))) {
                document.querySelectorAll('.menu-www > li, .submenu-www > li').
                forEach((itm) => { itm.classList.remove('active'); });
                menuOpened = false;
            }
        }
    });
    
    document.querySelectorAll('.action-search').forEach((itm) => {
        itm.addEventListener('click', () => {
            document.body.classList.toggle('show-search');
            if (document.body.classList.contains('show-search')) {
                document.querySelector('.search-panel input').focus();
            }
            
        });
    });
    document.querySelector('.search-panel .close').
    addEventListener('click', () => {
        document.body.classList.remove('show-search');
    });
    
    // based on document HREF location, determines which menu item should be active
    function setActiveMenu() {
        // path + hash
        const path = window.location.pathname + window.location.hash;
        document.querySelectorAll('a[href="' + path + '"]').forEach((itm) => {
            const li = itm.closest('li');
            if (li) {
                li.classList.add('current');
                menuId = li.id;
                if (!menuId) {
                    const parent = li.parentNode.closest('li');
                    if (parent) {
                        menuId = parent.id;
                    }
                }
                const menu = document.querySelector(
                    '[data-target="' + menuId + '"]');
                if (menu) {
                    menu.classList.add('current');
                }
            }
        });
        
    }
    
    setActiveMenu();
    const SCROLL_MENU_THRESHOLD = 100;
    window.addEventListener('scroll', () => {
        if (document.body.scrollTop > SCROLL_MENU_THRESHOLD ||
            document.documentElement.scrollTop > SCROLL_MENU_THRESHOLD) {
            document.body.classList.add('scrolled');
        }
        else {
            document.body.classList.remove('scrolled');
        }
    });
   
});